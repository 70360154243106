import * as axios from 'axios';
import React, { useState, useEffect } from 'react'

import { useNavigate } from 'react-router-dom';
import { FieldControl, ButtonControl } from 'spark-core-dx/components'
import { useValidationErrors, useCoreRoute } from 'spark-core-dx/hooks'
import { SystemTypes } from 'spark-core-dx/services'
import { useCoreStateContext, useCoreContext, useAuthContext } from 'spark-core-dx/contexts';
import { ItemServiceFactory } from '../../services/ItemServiceFactory';
import './Login.scss';

const Login = ({ }) => {
   const navigate = useNavigate()

   const coreContext = useCoreContext();
   const coreState = useCoreStateContext();
   const { coreNavigate } = useCoreRoute();
   const { coreLogin } = useAuthContext();
   var authGuardState = coreState.get('AuthGuard');

   const { hasAndShowValidationErrors } = useValidationErrors();
   const [item, setItem] = useState({
      Key: "03c00cef0ce7a23761c0b04f74f7d3ca51751fbc5e24ea432d02ea30e0b128ca",
      Secret: "FDgUepqO6LfZrKmTzBtn0Dk0DcAlP19Xi1U4hz0poH73q8reCrPxtIyg9GFcETLq9sdzhhlI0aWmMo2dKOZU0g"
   })
   const [error, setError] = useState(null);
   const [loading, setLoading] = useState(false);
   const coreAuthFactory = new ItemServiceFactory();
   const coreAuthService = coreAuthFactory.GetItemService(coreAuthFactory.ItemServiceEnum.CoreAuthCredential, coreState, coreContext)

   const handleOnChange = (value, field, obj) => {
      const updatedItem = { ...item };
      updatedItem[field] = value;
      setItem(updatedItem);
   }

   const handleLogin = async () => {
      if (!hasAndShowValidationErrors()) {
         //DO the login here
         coreAuthService.postOperation('CoreAuthenticate', null, item, null, null, true).then(r => {
            coreLogin(r.first().Items.first().Tokens);
            coreNavigate("/deploy/config-setup", ["useCoreAuth=true"])
         })
      }
      else {
         setError(null);
      }
   }

   useEffect(() => {
      if (authGuardState?.IsAuthenticated) {
         navigate('/setup')
      }
   }, [authGuardState])

   return (
      <div className={'login-screen'}>
         <h2>Core Portal: Startup Login</h2>
         <div className={'login-screen-form'}>
            {error && <h4 className={'error'}>
               {error}
            </h4>}
            <FieldControl fieldMetadata={{
               FieldName: "Key",
               IsRequired: true,
               DisplayName: "Key",
               FieldType: SystemTypes.UIFieldType.TextBox,
               MaxLength: 500
            }}
               disableError={false}
               value={item["Key"]}
               onChange={handleOnChange}
            />
            <FieldControl fieldMetadata={{
               FieldName: "Secret",
               IsRequired: true,
               DisplayName: "Secret",
               FieldType: SystemTypes.UIFieldType.Password,
               MaxLength: 500
            }}
               disableError={false}
               value={item["Secret"]}
               onChange={handleOnChange}
            />
            <div className={'login-screen-button'}>
               <ButtonControl loading={loading} type={'okay'} onClick={handleLogin}>Login</ButtonControl>
            </div>
         </div>
      </div>
   )

}

export default Login;



