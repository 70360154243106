/*
    CAUTION: PLEASE READ! This File was auto generated by the system.
    Any changes you make will be removed the next time it's generated.
*/
import { ItemService } from 'spark-core-dx/services'

export const Operations = {
    ApplicationItem: {
        AuthGuardInitialize: "AuthGuardInitialize",
        SelectDataApplications: "SelectDataApplications",
        SelectDataTestLarge: "SelectDataTestLarge",
        SelectDataTestMedium: "SelectDataTestMedium",
        SelectDataTestSmall: "SelectDataTestSmall",
    },
    DeployItem: {
        Deploy: "Deploy",
    },
    UITestItem: {
        UITestEventProcessor: "UITestEventProcessor",
    },
}

export class PortalAppConfigItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/PortalAppConfig", coreState, coreContext, "APP", null, isWarning);
    }

}

export class DeployItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/Deploy", coreState, coreContext, "APP", null, isWarning);
    }

}

export class EnvironmentItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/Environment", coreState, coreContext, "APP", null, isWarning);
    }

}

export class PipelineTemplateItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/PipelineTemplate", coreState, coreContext, "APP", null, isWarning);
    }

}

export class QAComplexItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/QAComplex", coreState, coreContext, "APP", null, isWarning);
    }

}

export class QAStandardItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/QAStandard", coreState, coreContext, "APP", null, isWarning);
    }

}

export class QAValidationItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/QAValidation", coreState, coreContext, "APP", null, isWarning);
    }

}

export class UITestItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/UITest", coreState, coreContext, "APP", null, isWarning);
    }

}

