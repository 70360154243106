import React, { createContext, useState, useEffect, useContext } from 'react';
import { useCoreStateContext } from 'spark-core-dx/contexts';
import { ItemServiceFactory as PortalItemServiceFactory } from '../../services/ItemServiceFactory';
import { ItemServiceFactory } from 'spark-core-dx/services';
import { useGenericManager } from 'spark-core-dx/hooks';

export const DeployDomainContext = createContext();

export function DeployDomainContextProvider({ children }) {
    const coreState = useCoreStateContext();
    const configMgr = useGenericManager(ItemServiceFactory.ItemServiceEnum.Config, new PortalItemServiceFactory());

    //configData will be default corePortalPROD
    //configMultiple will return all of existing apps
    const [configData, setConfigData] = useState([])
    const [configMultiple, setConfigMultiple] = useState([])
    
    //Get data for config, and expose it here
    //For Core Auth: List of disabled applications, in the future that list goes 
    //Same with environment, 
    //Smart tool, take existing config and revise or start from scratch
    
    useEffect(() => {
        configMgr?.search({searchModel: "x => x.RegisteredAppId == appId && x.IsActive == true && x.Environment == env", params: {appId : window.configData.APP_IDS["APP"], env: "PROD"}}).then( r => {
            const updatedData = [...configData, ...r]
            setConfigData(updatedData)
        })

        configMgr?.search({searchModel: "", params: {}}).then( r => {
            const updatedData = [...configData, ...r]
            setConfigMultiple(updatedData)
        })
        
        return () => {
            coreState.clear()
        }
    }, [])

    
    return (
        <DeployDomainContext.Provider value={{configData, configMultiple, configMgr}}>
            {children}
        </DeployDomainContext.Provider>

    );
}
export const useDeployDomainContext = () => useContext(DeployDomainContext);