import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { ButtonControl} from 'spark-core-dx/components'
import { useCoreRoute} from 'spark-core-dx/hooks'

import './home.scss'
import { useAuthContext } from 'spark-core-dx/contexts';


const Home = ({ }) => {

    const { coreNavigate,goHome } = useCoreRoute();
    const { isAuthorized } = useAuthContext();
    const location = useLocation()

    useEffect(()=>{
        if(location.pathname == "/" && isAuthorized){
            goHome();
        }
    },[location.pathname])

    return (
        <div className="home">
            <div className="home-landing">
                <h1>Welcome to the <b>Configuration Portal</b></h1>
                <div className="statement">
                    <h4 className="title">This application has been created for internal use only.</h4>
                    <p>Unauthorized access or use of this system is strictly prohibited and may result in disciplinary action, civil, or criminal penalties. All activities within this system are monitored and logged for security purposes.</p>
                </div>
                <ButtonControl  type={'okay'} onClick={()=>{     coreNavigate("/login/")}}>Secure Login</ButtonControl>
            </div>
        </div>
    )
}

export default Home;



