import React, { useState, useEffect } from 'react'
import { FormControl, ButtonControl, FieldControl, MultiSelectListControl } from 'spark-core-dx/components'
import { SystemTypes } from 'spark-core-dx/services';
import { useCoreStateContext } from 'spark-core-dx/contexts';
import { useDeployDomainContext } from '../DeployDomainProvider';
import { useSearchParams } from 'react-router-dom';
import { useCoreRoute } from 'spark-core-dx/hooks'

import DeployApp from '../components/DeployApp';
import "./Setup.scss"

const Setup = ({ fixedNav }) => {

    //Context Initiations
    const coreState = useCoreStateContext();
    const { coreNavigate } = useCoreRoute();
    const [searchParams] = useSearchParams();
    const { configData, configMultiple, configMgr } = useDeployDomainContext();

    //Used to determine what form type to proceed with
    const isAuthGuard = coreState.get('AuthGuard') ? coreState.get('AuthGuard') : true;
    const isCoreAuth = searchParams.get("useCoreAuth") === true;

    const [editedMetadata, setEditedMetadata] = useState(configMgr?.uiMetadata)
    const [isLoading, setIsLoading] = useState(true);

    /**
     * DeployFormType:
     * select-menu -> Show Button Option for "New Application" and "Existing Application"
     * new-app -> Loads form for "New Application"
     * existing-app -> Loads app selection for "Existing Application"
     * core-auth-setup -> Loads pre-generated AuthGuard form when logged in through coreAuth
     */
    const [deployFormType, setDeployFormType] = useState("select-menu");
    const [deployReady, setDeployReady] = useState(false);
    const [deployFormData, setDeployFormData] = useState({});

    //Used to check for existing environments, they cannot be recreated
    const [availableEnvironments, setAvailableEnvironments] = useState([]);
    const [availableApps, setAvailableApps] = useState([]);
    const [selectedApp, setSelectedApp] = useState(null);
    const [nextPage, setNextPage] = useState(false);
    const [disabledEnvs, setDisabledEnvs] = useState([]);

    //Used to check for existing applications and data
    const [existingData, setExistingData] = useState(null)
    const [formattedJSON, setFormattedJSON] = useState([])

    const [authSetupReformat, setAuthSetupReformat] = useState({});
    const [changedConfig, setChangedConfig] = useState({});

    //Used for handling validation errors
    const [selectionValid, setSelectionValid] = useState(true)

    //AuthGuard Setup Form for when logged in through CoreAuth
    useEffect(() => {
        if (configMgr?.uiMetadata === undefined) {
            setIsLoading(true)
        } else {
            if (isCoreAuth) {
                const setup = {
                    ...configData[0],
                    SolutionName: configData[0]?.SolutionName?.replace("CorePortal", "AuthGuard"),
                    WebRepoName: configData[0]?.WebRepoName.replace("CorePortal", "AuthGuard"),
                    FolderInRepo: "authguard",
                    APIProjectName: configData[0]?.APIProjectName.replace("Portal", "AuthGuard"),
                    WebSiteUri: configData[0]?.WebSiteUri.replace("PROD", configData[0]?.Environment).replace("CorePortal", "AuthGuard"),
                    FuncAPIUri: configData[0]?.FuncAPIUri.replace("PROD", configData[0]?.Environment).replace("CorePortal", "AuthGuard"),
                    RegisteredAppId: "266e8365-b25c-4592-a942-8cc1396ef51c"
                }
                setAuthSetupReformat(setup)
                setDeployFormType("core-auth-setup")
            }

            //Updating uimetadata parameters
            const editedFields = configMgr.uiMetadata?.first().Fields.map((x) => {
                return (x?.FieldName === "SolutionName" || x?.FieldName === "Environment" || x?.FieldName === "RegisteredAppId" || x?.FieldName === "DeploymentStage" || x?.FieldName === "DeploymentComplete") ? { ...x, FieldType: 11 } : x;
            })

            const newMetadata = {
                ...configMgr?.uiMetadata.first(),
                Fields: editedFields
            }

            setAvailableApps(JSON.parse(configMgr?.uiMetadata.first().Fields[0].DataJson))
            setAvailableEnvironments(JSON.parse(configMgr?.uiMetadata.first().Fields[1].DataJson))
            setEditedMetadata([newMetadata])
            setIsLoading(false)
        }
    }, [configMgr?.uiMetadata])


    //Maps existing applications for selectlist
    useEffect(() => {
        const format = configMultiple.map((r) => {
            return {
                value: r?.SolutionName + "," + r?.Environment,
                Text: r?.SolutionName + " - " + r?.Environment
            }
        })
        setFormattedJSON(format)
    }, [configMultiple])

    //Creating new config, populating existing prod data to new
    useEffect(() => {
        const filtered = configMultiple.filter((x) => {
            return x?.Environment === "PROD"
        })
        const prod_app = filtered.filter((x) => {
            return x?.SolutionName === selectedApp?.name
        })

        //NOTE: SWITCH LOCAL TO PROD FOR COREPORTAL
        const editedConfig = (prod_app?.length !== 0 && prod_app !== null) ?
            // Case: Found a PROD version of application
            {
                ...prod_app[0],
                WebSiteUri: prod_app[0]?.WebSiteUri.replace("PROD", selectedApp?.selected_env),
                FuncAPIUri: prod_app[0]?.FuncAPIUri.replace("PROD", selectedApp?.selected_env),
                SolutionName: selectedApp?.name,
                Environment: selectedApp?.selected_env
            } :
            // Case: No existing PROD version of application
            {
                ...configData[0],
                SolutionName: selectedApp?.name,
                WebRepoName: configData[0]?.WebRepoName.replace("CorePortal", selectedApp?.name),
                APIProjectName: configData[0]?.APIProjectName.replace("Portal", selectedApp?.name),
                WebSiteUri: configData[0]?.WebSiteUri.replace("PROD", selectedApp?.selected_env).replace("CorePortal", selectedApp?.name),
                FuncAPIUri: configData[0]?.FuncAPIUri.replace("PROD", selectedApp?.selected_env).replace("CorePortal", selectedApp?.name),
                Environment: selectedApp?.selected_env
            }

        setChangedConfig(editedConfig)
        setDisabledEnvs(selectedApp?.existing_environments)
    }, [selectedApp])

    //Handle Selections for Existing Apps and Environments
    const handleSelect = (e) => {
        let appValues = e?.split(",")
        if (e !== "UNK") {
            let exist = {}
            configMultiple.forEach((x) => {
                if (x?.SolutionName === appValues[0] && x?.Environment === appValues[1]) {
                    exist = x;
                }
            })
            setExistingData(exist)
        } else {
            setSelectionValid(false)
        }
    }

    const handleSelectNewApp = (e, type) => {
        switch (type) {
            case 'app':
                const existing_environments = configMultiple.filter((x) => x?.SolutionName === e).map((x) => {
                    return x?.Environment
                })

                const createable_envs = availableEnvironments.filter((x) => {
                    return !existing_environments.includes(x?.Value)
                })

                const obj = {
                    name: e,
                    existing_envs: existing_environments,
                    createable_envs: createable_envs,
                    selected_env: "UNK"
                }
                setSelectedApp(obj)
                break;
            case 'env':
                const updatedObj = {
                    ...selectedApp,
                    selected_env: e
                }
                setSelectedApp(updatedObj)
                break;
        }

    }

    //Handles saving existing and new environments
    const handleOnSave = async (item, isNew) => {
        const copiedFormData = {
            ...deployFormData,
            ...item,
        }

        setDeployFormData(copiedFormData)
        setIsLoading(true)

        if (isNew) {
            delete copiedFormData._id
            delete copiedFormData.UniqueId
            delete copiedFormData.CreatedById
            delete copiedFormData.CreatedBy
            delete copiedFormData.CreatedDate
            delete copiedFormData.UpdatedBy
            delete copiedFormData.UpdatedById
            delete copiedFormData.UpdatedDate
            
            copiedFormData.DeploymentStage = 0
            copiedFormData.IsNew = true;
        }

        await configMgr.saveItem(copiedFormData).then(r => {
            setIsLoading(false)
            if (r?.Success) {
                setDeployReady(true)
                if (copiedFormData?.DeploymentStage === 2) {
                    coreNavigate("/deploy/", [`appId=${copiedFormData?.RegisteredAppId}`, `env=${copiedFormData?.Environment}`, `redeploy=true`])
                } else {
                    coreNavigate("/deploy/", [`appId=${copiedFormData?.RegisteredAppId}`, `env=${copiedFormData?.Environment}`, `redeploy=false`])
                }
            }
        })
        coreState.set(Date.now(), "forceUpdateConfig")
    }

    const handleReturnButton = () => {
        if (nextPage) {
            setNextPage(false);
        } else {
            setDeployFormType("select-menu")
            setExistingData(null)
            setSelectedApp(null)
            setDeployReady(false)
        }
    }

    //Confirm refresh dialogue
    window.onbeforeunload = () => {
        if (deployFormData !== "select-menu" && nextPage) {
            return ""
        }
    }


    const renderFormType = () => {
        switch (deployFormType) {
            case "select-menu":
                return (
                    <div className='setup-applications'>
                        <ButtonControl value={"New Application"} type={"okay"} minWidth={"17rem"} onClick={() => { setDeployFormType("new-app") }} />
                        <ButtonControl value={"Existing Application"} type={"okay"} minWidth={"17rem"} onClick={() => { setDeployFormType("existing-app") }} />
                    </div>
                )
            case "new-app":
                return (
                    <div className='form-control-new-options-wrapper'>
                        {
                            (!nextPage && !deployReady) &&
                            <div className='form-control-select-wrapper'>
                                <div className='form-control-app-select'>
                                    <h2>Select the application configuration you want to create.</h2>
                                    <FieldControl fieldMetadata={{
                                        FieldName: "Application",
                                        DisplayName: "Application",
                                        FieldType: SystemTypes.UIFieldType.SelectList,
                                        DataJson: availableApps
                                    }}
                                        value={selectedApp?.name}
                                        error={true}
                                        disableError={true}
                                        onChange={(o) => handleSelectNewApp(o, 'app')}
                                    />
                                </div>
                                {
                                    (selectedApp?.name !== "UNK" && selectedApp !== null) &&
                                    <div className='form-control-env-select'>
                                        <h2>Select a new environment you want to create.</h2>
                                        <FieldControl fieldMetadata={{
                                            FieldName: "Environment",
                                            DisplayName: "Environment",
                                            FieldType: SystemTypes.UIFieldType.SelectList,
                                            DataJson: selectedApp?.createable_envs
                                        }}
                                            value={selectedApp?.selected_env}
                                            error={true}
                                            disableError={true}
                                            onChange={(o) => handleSelectNewApp(o, 'env')}
                                        />
                                    </div>
                                }
                                <ButtonControl type={"okay"} value={"Next"} onClick={() => { setNextPage((selectedApp?.selected_env !== "UNK" && selectedApp?.name !== "UNK") && selectedApp !== null ? true : false) }} />
                            </div>
                        }
                        {
                            nextPage &&
                            <FormControl
                                className={"form-config"}
                                isWizard={true}
                                itemData={changedConfig}
                                metadata={configMgr.uiMetadata?.first()}
                                formFields={editedMetadata.first().Fields}
                                onSave={(e) => handleOnSave(e, true)}
                            />
                        }
                    </div>
                )
            case "existing-app":
                return (
                    <div className='form-control-existing-options-wrapper'>
                        {
                            (!nextPage && !deployReady) &&
                            <div className='form-control-select-wrapper'>
                                <div className='form-control-app-select'>
                                    <h2>Select the application configuration you want to edit.</h2>
                                    <FieldControl fieldMetadata={{
                                        DisplayName: "Application",
                                        FieldType: SystemTypes.UIFieldType.SelectList,
                                        DataJson: formattedJSON
                                    }}
                                        value={existingData?.SolutionName + "," + existingData?.Environment}
                                        error={true}
                                        disableError={true}
                                        onChange={(e) => handleSelect(e)}
                                    />
                                </div>
                                <ButtonControl type={"okay"} value={"Next"} onClick={() => {
                                    if (existingData !== null) {
                                        setNextPage(true)
                                        setSelectionValid(false)
                                    } else {
                                        setSelectionValid(true)
                                    }
                                }} />
                            </div>
                        }
                        {
                            nextPage &&
                            <FormControl
                                className={"form-config"}
                                isWizard={true}
                                itemData={existingData}
                                metadata={configMgr.uiMetadata?.first()}
                                formFields={editedMetadata.first().Fields}
                                onSave={(e) => handleOnSave(e, false)}
                            />
                        }
                    </div>
                )
            case "core-auth-setup":
                return (
                    <FormControl
                        className={"form-config" + (fixedNav ? "" : " scroll")}
                        isWizard={true}
                        itemData={authSetupReformat}
                        metadata={configMgr.uiMetadata?.first()}
                        formFields={editedMetadata.first().Fields}
                        onSave={(e) => handleOnSave(e, true)}
                    />
                )
        }
    }
    return (
        <div className='setup-wrapper'>
            {
                (!isAuthGuard && !isCoreAuth) &&
                <div className='setup-error'>
                    <h1 className='setup-error-header'>{`Please enable either AuthGuard or CoreAuth before proceeding.`}</h1>
                    <h3 className='setup-error-login' onClick={() => {
                        coreNavigate("/login/")
                    }}>Login</h3>
                </div>
            }
            {
                (isAuthGuard || isCoreAuth) &&
                <div className='setup-wizard-container'>
                    <div className='setup-header-container'>
                        {
                            (deployFormType !== "select-menu" && deployFormType !== "core-auth-setup" && !isLoading && !deployReady) &&
                            <ButtonControl type={"okay"} value={nextPage ? "back to selection" : "menu"} className={"previous-button"} onClick={() => {
                                handleReturnButton()
                            }} />
                        }
                        <div className='deployment-status-header'>
                            <h1 className='setup-header'>{deployFormType !== "core-auth-setup" ? "Deployment Setup Wizard" : "AuthGuard Deployment Setup Wizard"}</h1>
                            <h3 className='setup-subheader'>
                                {
                                    deployFormType === "existing-app" ? "Editing" + (existingData ? ": " + existingData?.SolutionName + " - " + existingData?.Environment : "") :
                                        (deployFormType === "new-app" ? "Creating" + (selectedApp ? ": " + selectedApp?.name + (selectedApp?.selected_env !== "UNK" ? " - " + selectedApp?.selected_env : "") : "") :
                                            "Create New or Edit Existing")
                                }
                            </h3>
                        </div>
                    </div>
                    {
                        isLoading &&
                        <h2 className='setup-loading'>Loading...</h2>
                    }
                    {/*Remove after completing deployment */}
                    {
                        (!deployReady && !isLoading) &&
                        renderFormType()
                    }
                    {/* {
                        deployReady &&
                        <div className='deploy-form'>
                            <DeployApp passedData={deployFormData} />
                        </div>
                    } */}
                </div>
            }
        </div>
    )

}

export default Setup;



