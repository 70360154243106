import React from 'react';
import { Route } from 'react-router-dom';
import { SparkApp } from 'spark-core-dx';
import { DomainRoute } from 'spark-core-dx/domains';
import { useCoreRoute } from 'spark-core-dx/hooks';
import { useCoreContext } from 'spark-core-dx/contexts';
import Home from './domains/home/screens/Home';
import HomeDomain from './domains/home/HomeDomain';
import CoreAuthDomain from './domains/coreAuth/CoreAuthDomain';
import Login from './domains/coreAuth/Login';
import Setup from './domains/deploy/screens/Setup';
import DeployDomain from './domains/deploy/DeployDomain';
import Deployment from './domains/deploy/screens/Deployment';
const App = () => {
  //	"CoreAuthKey": "03c00cef0ce7a23761c0b04f74f7d3ca51751fbc5e24ea432d02ea30e0b128ca",
  //  "CoreAuthSecret": "FDgUepqO6LfZrKmTzBtn0Dk0DcAlP19Xi1U4hz0poH73q8reCrPxtIyg9GFcETLq9sdzhhlI0aWmMo2dKOZU0g"
  const { getRoute } = useCoreRoute()
  // console.log(getRoute('/coreauth/login/', 'CoreAuth'), '??')\

  return (
    <SparkApp
       addonRoutes={[
        <Route key={'Public'} element={<DomainRoute domainName={'Public'} component={HomeDomain} />} >
          <Route exact path={getRoute('/home/', 'Public')} element={<Home />} />
        </Route>,
        <Route key={'CoreAuth'} element={<DomainRoute domainName={'CoreAuth'} permissions={{ setup: ["InitalizeCorePortal"] }} component={CoreAuthDomain} />} >
          <Route path={getRoute('/coreauth/login/', 'CoreAuth')} element={<Login />} />
          <Route exact path={getRoute('/coreauth/setup/', 'CoreAuth')} element={<Setup fixedNav={true} />} />
        </Route>,
        <Route element={<DomainRoute domainName={'Deploy'} component={DeployDomain} />} >
          <Route exact path={getRoute('/deploy/configsetup/', 'Deploy')} element={<Setup />} />
          <Route exact path={getRoute('/deploy/', 'Deploy')} element={<Deployment />} />
        </Route>
      ]} />
  )
}

export default App;
