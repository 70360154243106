import React, { useEffect, useState } from 'react';
import { FieldControl, ButtonControl, IconControl } from 'spark-core-dx/components'
import { useGenericManager, useCoreRoute } from 'spark-core-dx/hooks'
import { SystemTypes } from 'spark-core-dx/services';
import { ItemServiceFactory } from '../../../services/ItemServiceFactory';
import { useCoreStateContext, useCoreContext } from 'spark-core-dx/contexts';
import _ from 'lodash'
import * as axios from 'axios';

import './DeployApp.scss'
import { useDeployDomainContext } from '../DeployDomainProvider';
import { useSearchParams } from 'react-router-dom';

const DeployApp = ({ passedData, returnData }) => {
    const itemFactory = new ItemServiceFactory();

    const { configMgr } = useDeployDomainContext();
    const [searchParams] = useSearchParams();
    const coreContext = useCoreContext();
    const coreState = useCoreStateContext();

    const deployService = itemFactory.GetItemService(itemFactory.ItemServiceEnum.Deploy, coreState, coreContext)
    const appService = itemFactory.GetItemService(itemFactory.ItemServiceEnum.Application, coreState, coreContext)
    const logMgr = useGenericManager(itemFactory.ItemServiceEnum.Log, null, true)

    /**
     * TODO: 
     * - Implement pingVM function (pings the VM, sets VMActive to true once the ping returns that VM is active)
     */

    const [deployForm, setDeployForm] = useState({
        AppIdToDeploy: passedData?.RegisteredAppId,
        Env: passedData?.Environment,
        SolutionName: passedData?.SolutionName
    })

    const [config, setConfig] = useState(null)
    const [eventId, setEventId] = useState(null)
    const [allowRedeploy, setAllowRedeploy] = useState(false)
    const [deploymentStatus, setDeploymentStatus] = useState("start")
    //deployment status: start, in-progress, done, completed, failure-start

    const [logs, setLogs] = useState(["Pinging VM..."])
    const [animationsIndex, setAnimationIndex] = useState(0)
    const [expandConsole, setExpandConsole] = useState(false)

    //State for implementing instructions if there are any
    const [warningMsgs, setWarningMsgs] = useState("");
    const [isSiteActive, setIsSiteActive] = useState(false)
    const [isFunctionActive, setIsFunctionActive] = useState(false)
    const [isVMActive, setIsVMActive] = useState(false)
    const [noBackButton, setNoBackButton] = useState(false)

    const [clientKS, setClientKS] = useState({ key: null, secret: null })
    const [isLoading, setIsLoading] = useState(true)
    const [clientKSStatus, setClientKSStatus] = useState("None")

    const isAuthGuard = coreState.get('AuthGuard') ? coreState.get('AuthGuard') : true;
    const isCoreAuth = searchParams.get("useCoreAuth") === true;
    const needsAuthDeploy = !isAuthGuard && isCoreAuth


    /**
     * Check Deployment Status:
     * Null: Set to start
     * 0 - Not Started: Set to start
     * 1 - In Progress: Set to logs
     * 2 - Completed: Set to completion screen
     * 3 - Failure: Set to logs failure
     */

    const grabConfig = async () => {
        let r = await configMgr.Query({ model: "x => x.RegisteredAppId == appId && x.Environment == env && x.IsActive == true && x.IsDeleted == false", params: { appId: deployForm.AppIdToDeploy, env: deployForm.Env } })
        if (r.Success) {
            setConfig(r.Items.first())
            return r.Items.first()
        }
    }

    console.log('is it redeployable', config)

    useEffect(() => {
        let stage = config?.DeploymentStage
        if (isLoading) {
            switch (stage) {
                case 0:  //Not started
                    setDeploymentStatus("start")
                    setWarningMsgs("This will deploy the new application within the environment.")
                    setIsLoading(false)
                    break;
                case 2:  //Completed
                    if (passedData?.Redeploy === "true") {
                        setDeploymentStatus("failure-start")
                        setAllowRedeploy(true)
                        setIsLoading(false)
                        setNoBackButton(true)
                    } else {
                        setDeploymentStatus("completed")
                        setAllowRedeploy(true)
                        setIsLoading(false)
                    }
                    setWarningMsgs("This will redeploy the existing application within the environment")
                    break;
                case 1:  //In Progress
                case 3:  //Failure
                    setDeploymentStatus("failure-start")
                    setAllowRedeploy(true)
                    setIsLoading(false)
                    setWarningMsgs(stage === 1 ? "Something went wrong last deployment. Please redeploy" : "Deployment failed. Please try redeployment.")
                    setNoBackButton(stage === 1 ? false : true)
                    grabConfig()
                    break;
                default:
                    setDeploymentStatus("loading")
                    break;
            }
        }
    }, [config?.DeploymentStage])

    useEffect(() => {
        if (!eventId)
            return

        const intervalId = setInterval(() => {
            //3051 - Error in Deployment. clearInterval().
            //2007 - Deployment Completed, clearInterval().
            logMgr.Query({ model: 'x => x.EventId == eventId && (x.MessageTypeId == 3051 || x.MessageTypeId == 1005 || x.MessageTypeId == 2007)', params: { eventId } }).then(r => {
                if (r?.Success) {
                    const newItems = r.Items.map(x => x.MessageDetails)
                    if (r.Items.some(x => x.MessageTypeId == 3051 || x.MessageTypeId == 2007)) {
                        handleClearInterval()
                        if (r.Items.some(x => x.MessageTypeId == 2007)) {
                            grabConfig()
                            setLogs([...newItems, "Please approve and deploy all pipelines, the system will verify the accessibility of the deployment"])
                            setDeploymentStatus("done")
                            configMgr.saveItem({
                                ...config,
                                DeploymentStage: 2,
                                DeploymentComplete: true
                            })
                        }
                        if (r.Items.some(x => x.MessageTypeId == 3051)) {
                            setDeploymentStatus("failure")
                            configMgr.saveItem({
                                ...config,
                                DeploymentStage: 3,
                                DeploymentComplete: false
                            })
                            setLogs([...newItems, "Failure to deploy application."])
                            clearInterval(intervalId)
                        }
                    } else {
                        setLogs(prevLogs => {
                            const newLogs = newItems.filter(item => !prevLogs.includes(item))
                            return prevLogs?.length !== newLogs.length ? [...prevLogs, ...newLogs] : []
                        })
                    }
                }

            })
        }, 5000);

        const handleClearInterval = () => {
            setAllowRedeploy(true)
            setEventId(null)
            clearInterval(intervalId)
        }
        return () => clearInterval(intervalId);
    }, [eventId, logs])

    useEffect(() => {
        let pingInterval;
        if (!config) {
            grabConfig()
            return
        } else if (config && logs.includes("Please approve and deploy all pipelines, the system will verify the accessibility of the deployment") && !logs.includes("Verifying Website and Azure Functions Deployment")) {
            setLogs(prevLogs => [...prevLogs, "Verifying Website and Azure Functions Deployment"])
            pingFunction()
            pingWebsite()
        }
    }, [config, logs])

    const handleOnChange = (value, fieldName) => {
        const copiedDeployedForm = { ...deployForm }
        copiedDeployedForm[fieldName] = value
        setDeployForm(copiedDeployedForm)
    }

    const deploy = async (updatedConfig) => {
        const config = updatedConfig ?? await grabConfig()
        if (!config.DeploymentEventId) {
            //Only execute if coreauth is on, authguard is not enabled
            if (needsAuthDeploy) {
                await appService.getOperation('AuthGuardInitalize', deployForm.AppIdToDeploy, { env: deployForm.Env })
            }
            try {
                configMgr.saveItem({
                    ...config,
                    DeploymentStage: 1,
                    DeploymentComplete: false
                })
                await deployService.postOperation('Deploy', null, deployForm, null, null, true).then(r => {
                    setEventId(r.first().CorrelationID)
                })
            } catch {
            }
        } else {
            setEventId(config.DeploymentEventId)
        }
    }

    const findClientKeySecret = async () => {
        try {
            setClientKSStatus("Loading...")
            await configMgr.RunOperation({ operationName: "GetClientKeySecret", item: config }).then(r => {
                if (r?.Items !== null) {
                    let ids = r?.Items[0];
                    setClientKS({
                        key: ids?.UniqueId,
                        secret: ids?.Secret
                    })
                } else {
                    console.log("Could not find the associated client key/secret")
                }
            })
        } catch {
        }
        setClientKSStatus("Done")
    }

    const pingFunction = async () => {
        axios.get(`https://${config.FuncAPIUri}/api/ping`).then(r => {
            setLogs(prevLogs => [...prevLogs, `Successfully accessed azure functions at https://${config.FuncAPIUri}/api/ping`])
            setIsFunctionActive(true)
        }).catch(e => {
            console.log("Function Errors:", e)
            setTimeout(pingFunction, 5000)
            setIsFunctionActive(false)
        })
    };

    const pingWebsite = async () => {
        //Look for a 200, CORS error is valid.
        axios.get(`https://${config.WebSiteUri}`).then(r => {
            setLogs(prevLogs => [...prevLogs, `Successfully accessed website url at https://${config.WebSiteUri}`])
            setIsSiteActive(true)
        }).catch((e) => {
            console.log("Weburil Errors;", e)
            setTimeout(pingWebsite, 5000)
            setIsSiteActive(false)
        })
    };

    useEffect(() => {
        if (deploymentStatus === "in-progress") {
            pingVM()
        }
    }, [deploymentStatus])

    const pingVM = async () => {
        //Ping the virtual machine
        console.log("now pinging the vm")
    }

    useEffect(() => {
        if (logs.length === 0)
            return

        const timer = setInterval(() => {
            setAnimationIndex((prevIndex) => prevIndex + 1);
        }, allowRedeploy ? 250 : 2500);

        if (animationsIndex === logs.length - 1) {
            clearInterval(timer)
        }
        return () => {
            clearInterval(timer);
        };
    }, [logs, animationsIndex])

    const redeploy = async () => {
        setLogs(["Pinging VM..."])
        const newConfig = await grabConfig()
        const updatedConfig = { ...newConfig }
        updatedConfig.DeploymentEventId = null
        await configMgr._save(updatedConfig)
        deploy(updatedConfig);
    }

    useEffect(() => {
        returnData({ SolutionName: config?.SolutionName, Status: config?.DeploymentStage })
    }, [config])

    const [pingTimeout, setPingTimeout] = useState(true);

    // useEffect(() => {
    //     const checkTimeout = () => {
    //         setPingTimeout(true)
    //     }
    //     // setInterval(checkTimeout, 30000)
    //     setTimeout(checkTimeout, 10000)
    // }, [pingTimeout])

    return (
        <div className='deploy-container'>
            {
                deploymentStatus === "loading" &&
                <div className='loading-container'>
                    <h1>Loading...</h1>
                </div>
            }
            {
                (deploymentStatus === "start" || deploymentStatus === "failure-start") &&
                <div className="deploy">
                    <div className='header-warnings'>
                        <h3>{"WARNING: " + warningMsgs}</h3>
                    </div>
                    <FieldControl fieldMetadata={{
                        FieldName: "AppIdToDeploy",
                        DisplayName: "Application",
                        FieldType: SystemTypes.UIFieldType.Label,
                    }}
                        disableError={false}
                        value={config?.SolutionName ?? "Loading..."}
                    />
                    <FieldControl fieldMetadata={{
                        FieldName: "Env",
                        DisplayName: "Environment",
                        FieldType: SystemTypes.UIFieldType.Label
                    }}
                        disableError={false}
                        value={config?.Environment ?? "Loading..."}
                    />
                    <FieldControl fieldMetadata={{
                        FieldName: "AZSecret",
                        IsRequired: true,
                        DisplayName: "Azure Client Secret",
                        FieldType: SystemTypes.UIFieldType.TextBox,
                        MaxLength: 500
                    }}
                        disableError={false}
                        value={deployForm["AZSecret"]}
                        onChange={handleOnChange}
                    />
                    <FieldControl fieldMetadata={{
                        FieldName: "PAT",
                        IsRequired: true,
                        DisplayName: "Azure Dev Ops PAT",
                        FieldType: SystemTypes.UIFieldType.TextBox,
                        MaxLength: 500
                    }}
                        disableError={false}
                        value={deployForm["PAT"]}
                        onChange={handleOnChange}
                    />
                    {

                        allowRedeploy ?
                            <div className='start-footer-buttons'>
                                <ButtonControl onClick={() => {
                                    redeploy()
                                    setDeploymentStatus("in-progress")
                                    setIsVMActive(true)
                                }} type={"okay"}>Redeploy</ButtonControl>
                                {
                                    !noBackButton &&
                                    <ButtonControl onClick={() => {
                                        if (config?.DeploymentStage === 3) {
                                            setDeploymentStatus("failure")
                                            setIsVMActive(true)
                                        } else {
                                            setDeploymentStatus("completed")
                                            setIsVMActive(false)
                                        }
                                    }} type={"cancel"}>{"Post Deployment Page"}</ButtonControl>
                                }
                            </div>
                            :
                            <ButtonControl onClick={() => {
                                deploy()
                                setDeploymentStatus("in-progress")
                                setIsVMActive(true)
                            }} type={"okay"} disabled={config?.SolutionName ? false : true}>{config?.SolutionName ? "Deploy" : "Loading..."}</ButtonControl>
                    }
                </div>
            }
            {
                (deploymentStatus === "in-progress" || deploymentStatus === "failure" || deploymentStatus === "done" || deploymentStatus === "completed") &&
                <div className={'display-content-container ' + (isVMActive ? "" : " inactive")}>
                    <div className='console-content-wrapper'>
                        <div className='console-header'>
                            <h3 className='header-text'>Log Console</h3>
                            <h3 className='header-expand' onClick={() => setExpandConsole(!expandConsole)}>Expand</h3>
                        </div>
                        <div className={'typewriter-portion-wrapper' + (expandConsole ? " expanded" : "")}>
                            {
                                logs?.map((x, i) => {
                                    return <div className="typewriter-container" key={i}>
                                        <div className="typewriter-wrapper">
                                            <div className="typewriter"
                                                style={{
                                                    animation: i === animationsIndex ? `typing ${allowRedeploy ? .2 : 2}s steps(${x.length}) forwards, cursor .4s step-end infinite alternate` : '',
                                                    borderRight: i === animationsIndex ? '1ch solid' : '',
                                                    width: animationsIndex > i ? '100%' : '0'
                                                }}
                                            >
                                                {x}
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    {
                        expandConsole &&
                        <h3 className='minimize' onClick={() => setExpandConsole(!expandConsole)}>Minimize</h3>
                    }
                </div>
            }
            {
                deploymentStatus === "completed" &&
                <div className='app-cilent-credentials-wrapper'>
                    <div className='app-cilent-header'>
                        <h2>WARNING: The key and secret below will not show again after leaving this page. Please update ADO using them.</h2>
                    </div>
                    <div className='cred-container'>
                        <div className='key-secret-items'>
                            <FieldControl fieldMetadata={{
                                FieldName: "AppCilentKey",
                                DisplayName: "Application Cilent Key",
                                FieldType: SystemTypes.UIFieldType.Label,
                            }}
                                value={clientKSStatus === "Done" ? clientKS?.key : clientKSStatus}
                            />
                            <FieldControl fieldMetadata={{
                                FieldName: "AppCilentSecret",
                                DisplayName: "Application Cilent Secret",
                                FieldType: SystemTypes.UIFieldType.Label,
                            }}
                                value={clientKSStatus === "Done" ? clientKS?.secret : clientKSStatus}
                            />
                            <ButtonControl className={"key-secret-button"} value={"Generate Key and Secret"} type={"okay"} onClick={() => { findClientKeySecret() }} />
                            <div className='status-container'>
                                <div className='status-name site'>{"Site Status: " + (!isSiteActive && !pingTimeout ? "Pinging..." : (!pingTimeout ? "Done." : "Timed out."))}</div>
                                <div className='status-split-container'>
                                    <div className={'ping-spinner' + (!isSiteActive && !pingTimeout ? " loading" : " false")}></div>
                                    <div style={(isSiteActive ? { color: "green" } : { color: "red" })}>{(isSiteActive ? "ACTIVE" : "INACTIVE")}</div>
                                    {
                                        pingTimeout &&
                                        <div className='status-retry' title='Reping Site' onClick={() => {
                                            setPingTimeout(false)
                                            pingFunction()
                                            pingWebsite()
                                        }}>[O]</div>
                                    }
                                </div>
                            </div>
                            <div className='status-container' >
                                <div className='status-name function'>{"Function Status: " + (!isFunctionActive && !pingTimeout ? "Pinging..." : (!pingTimeout ? "Done." : "Timed out."))}</div>
                                <div className='status-split-container'>
                                    <div className={'ping-spinner' + (!isFunctionActive && !pingTimeout ? " loading" : " false")}></div>
                                    <div style={(isFunctionActive ? { color: "green" } : { color: "red" })}>{(isFunctionActive ? "ACTIVE" : "INACTIVE")}</div>
                                    {
                                        pingTimeout &&
                                        <div className='status-retry' title='Reping Function' onClick={() => {
                                            setPingTimeout(false)
                                            pingFunction()
                                            pingWebsite()
                                        }}>[O]</div>
                                    }

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            }
            <div className='footer-options'>
                <div className='status-footer'>
                    {(deploymentStatus === "failure" ? "Deployment failed. Please try redeploying again." : "")}
                    {((deploymentStatus === "completed" || deploymentStatus === "done") ? "Deployment Finished!" : "")}
                </div>
                <div className='footer-buttons'>
                    {
                        deploymentStatus === "done" &&
                        <ButtonControl type={"okay"} value={"next"} onClick={() => {
                            setDeploymentStatus("completed")
                            pingWebsite()
                            pingFunction()
                            setIsVMActive(!isVMActive)
                        }} />
                    }
                    {
                        deploymentStatus === "completed" &&
                        <ButtonControl type={"okay"} value={"view console"} onClick={() => {
                            setDeploymentStatus("done")
                            setIsVMActive(!isVMActive)
                        }} />
                    }
                    {
                        (deploymentStatus !== "start" && deploymentStatus !== "failure-start" && deploymentStatus !== "loading") &&
                        <ButtonControl
                            onClick={() => {
                                setDeploymentStatus(deploymentStatus === "failure" ? "failure-start" : "start")
                            }}
                            type={"okay"} disabled={(deploymentStatus !== "in-progress" && allowRedeploy) ? false : true}
                        >Redeploy</ButtonControl>
                    }
                </div>
            </div>
        </div>
    );
};

export default DeployApp;