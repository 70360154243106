import React, { useEffect, useState } from "react";
import Dashboard from "../components/Dashboard";

import "./Deployment.scss"
import DeployApp from "../components/DeployApp";
import { useSearchParams } from 'react-router-dom';

const Deployment = () => {
    const [searchParams] = useSearchParams();
    const appId = searchParams.get("appId")
    const env = searchParams.get("env")
    const redeploy = searchParams.get("redeploy")
    const [app, setApp] = useState();
    const [status, setStatus] = useState("");

    const obj = {
        RegisteredAppId: appId,
        Environment: env,
        Redeploy: redeploy
    }

    //Confirm refresh dialogue
    window.onbeforeunload = () => {
        return ""
    }

    const handleReturnData = (e) => {
        setApp(e)
        switch (e?.Status) {
            case 0:
                setStatus("New Application Deployment")
                break;
            case 1:
                setStatus("Redeployment")
                break;
            case 2:
                setStatus("Deployment Complete")
                break;
            case 3:
                setStatus("Deployment Failed")
                break;
        }
    }

    return (
        <div className="deployment-screen-wrapper">
            <div className="deployment-container">
                <div className="deploy-header-container">
                    <h1 className='setup-header'>Deployment</h1>
                    <h3 className='setup-subheader'>{app?.SolutionName !== undefined ? app?.SolutionName + " - " + env + " || Status: " + status : "Loading..."}</h3>
                </div>
                <div className="deploy-app-wrapper">
                    <DeployApp passedData={obj} returnData={handleReturnData} />
                </div>
            </div>
        </div>
    )
}

export default Deployment;