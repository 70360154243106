/*
    CAUTION: PLEASE READ! This File was auto generated by the system.
    Any changes you make will be removed the next time it's generated.
*/
import {
PortalAppConfigItemService         
,DeployItemService         
,EnvironmentItemService         
,PipelineTemplateItemService         
,QAComplexItemService         
,QAStandardItemService         
,QAValidationItemService         
,UITestItemService} from './ItemServices';
import {ItemServiceFactory as BaseItemServiceFactory} from 'spark-core-dx/services'

export class ItemServiceFactory {

get ItemServiceEnum() {
  return {
      ...BaseItemServiceFactory.ItemServiceEnum,
    PortalAppConfig:"PortalAppConfig",
    Deploy:"Deploy",
    Environment:"Environment",
    PipelineTemplate:"PipelineTemplate",
    QAComplex:"QAComplex",
    QAStandard:"QAStandard",
    QAValidation:"QAValidation",
    UITest:"UITest",
  }
}

GetItemService(itemServiceName, coreState, coreContext, isWarning) {
    if(itemServiceName === undefined) throw new Error('Item service name not found, please regenerate the Item Services to resolve this issue.');
    let itemService;
    switch(itemServiceName.toLowerCase()){
        case this.ItemServiceEnum.PortalAppConfig.toLowerCase():
             itemService = new PortalAppConfigItemService(coreState, coreContext, isWarning);
             break;
       
        case this.ItemServiceEnum.Deploy.toLowerCase():
             itemService = new DeployItemService(coreState, coreContext, isWarning);
             break;
        case this.ItemServiceEnum.Environment.toLowerCase():
             itemService = new EnvironmentItemService(coreState, coreContext, isWarning);
             break;
        case this.ItemServiceEnum.PipelineTemplate.toLowerCase():
             itemService = new PipelineTemplateItemService(coreState, coreContext, isWarning);
             break;
        case this.ItemServiceEnum.QAComplex.toLowerCase():
             itemService = new QAComplexItemService(coreState, coreContext, isWarning);
             break;
        case this.ItemServiceEnum.QAStandard.toLowerCase():
             itemService = new QAStandardItemService(coreState, coreContext, isWarning);
             break;
        case this.ItemServiceEnum.QAValidation.toLowerCase():
             itemService = new QAValidationItemService(coreState, coreContext, isWarning);
             break;
        case this.ItemServiceEnum.UITest.toLowerCase():
             itemService = new UITestItemService(coreState, coreContext, isWarning);
             break;
        default:
            itemService = BaseItemServiceFactory.GetItemService(itemServiceName, coreState, coreContext);
            break;
        }
        return itemService;
    }

ValidateItemName(itemName) {
    let isValid = false;
    for (const key in this.ItemServiceEnum){
        if (typeof(this.ItemServiceEnum[key]) === "string" &&
                this.ItemServiceEnum[key].toLowerCase() == itemName.toLowerCase())
                {
                    isValid = true;
                    break;
                }
        }
     return BaseItemServiceFactory.ValidateItemName(itemName);
 }
}
